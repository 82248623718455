import React, {useEffect, useReducer} from 'react';
import './utils/styles.css'
import './App.css';
import {IInputs,
    IOutputs,
    typeOfGoodsValues
} from "./utils/types";
import TimelineIcon from "./components/timeline-icon";
import ExposureIcon from "./components/exposure-icon";
import {
    borderColors,
    indicatorColors,
    initialInputData, initialOutputData,
    stateActions,
} from "./utils/constants";

const App = () => {

    type Action =
        | { type: stateActions.UPDATE_SHIPMENT_VALUE, shipmentValue: number }
        | { type: stateActions.UPDATE_EMAIL, emailAddress: string }
        | { type: stateActions.UPDATE_TYPE_OF_GOODS, typeOfGoods: string }
        | { type: stateActions.UPDATE_CHECKBOX_STATUS, checkboxIndex: number }


    const [inputState, dispatch] = useReducer(reducer, initialInputData);
    const [outputs, setOutputs] = React.useState<IOutputs>(initialOutputData);
    const [submitted, setSubmitted] = React.useState<boolean>(true);
    //Effect for updating outputs when inputs change

   function reducer(state: IInputs, action: Action): IInputs{

       switch (action.type){
           case stateActions.UPDATE_SHIPMENT_VALUE:
              return ({...state, shipmentValue: action.shipmentValue})
           case stateActions.UPDATE_EMAIL:
               return({...state, email: action.emailAddress})
           case stateActions.UPDATE_TYPE_OF_GOODS:
               return({...state, typeOfGoods: action.typeOfGoods})
           case stateActions.UPDATE_CHECKBOX_STATUS:
               const updatedCheckboxes = state.checkboxes;
               updatedCheckboxes[action.checkboxIndex].isChecked = !updatedCheckboxes[action.checkboxIndex].isChecked;
               return({...state, checkboxes : updatedCheckboxes })
       }
        return inputState;
   }


   useEffect(()=>{
    //Update the output state when the inputs have changed
       const selectedElementIndexes = new Set<number>();

       inputState.checkboxes.forEach((element, index)=>{
           if(element.isChecked){
               element.weight.forEach((element)=>{
                   selectedElementIndexes.add(element);
               })
           }
       })
       const selectedElementCount = selectedElementIndexes.size

       const timelineColors = getTimelineColors(selectedElementCount);


       const timeLineCopy  = outputs.timeline;
       let percentageCovered = Math.ceil(((selectedElementIndexes.size/7)*100)/5)*5;
       let riskedValue = Math.round((inputState.shipmentValue * ((100 - percentageCovered)/100))*100)/100;

       timeLineCopy.timelineElements.forEach((element, index)=>{
           element.selected = selectedElementIndexes.has(index);
       })

       //setTimeline(timeLineCopy);
       const startingPosition = selectedElementIndexes.size === 0?0:Math.min(...Array.from(selectedElementIndexes));
       const timelineIndicatorValue = {startingPosition:startingPosition, width: selectedElementIndexes.size, color: timelineColors.color, borderColor: timelineColors.borderColor}
       setOutputs({calcs: {currentExposure: (100- percentageCovered), valueAtRisk: riskedValue, elementsCoveredNumber:selectedElementCount, timelineCovered: percentageCovered}, timeline: timeLineCopy, timelineIndicator: timelineIndicatorValue})

   }, [inputState])

    const getTimelineColors = (selectedElementCount: number):{color: string, borderColor: string} =>{
       let timelineColors :{color: string, borderColor: string};
        switch(selectedElementCount){
            case 7:
                timelineColors = {color: indicatorColors.GREEN, borderColor: borderColors.GREEN}
                break;
            case 4:
                timelineColors = {color: indicatorColors.ORANGE, borderColor: borderColors.ORANGE}
                break;
            case 0:
                timelineColors = {color: indicatorColors.UNSELECTED, borderColor: borderColors.UNSELECTED}
                break;
            default:
                timelineColors = {color: indicatorColors.RED, borderColor: borderColors.RED}
                break;
        }
        return timelineColors;
    }

    const handleOnCheckboxChange = (position: number)=>{
        dispatch({type: stateActions.UPDATE_CHECKBOX_STATUS, checkboxIndex:position})
    }


    const handleOnShipmentValueChange = (event: any) =>{
        if(event.target.value === ''){
           // setShipmentValue(0);
            dispatch({type: stateActions.UPDATE_SHIPMENT_VALUE, shipmentValue:0})
        }else{
           // setShipmentValue(event.target.value);
            dispatch({type: stateActions.UPDATE_SHIPMENT_VALUE, shipmentValue:event.target.value})
        }
    }

    const handleOnEmailChange =(event: any) =>{
        dispatch({type: stateActions.UPDATE_EMAIL, emailAddress: event.target.value})
    }

    const handleOnGoodsChange = (event:any) =>{
        dispatch({type: stateActions.UPDATE_TYPE_OF_GOODS, typeOfGoods:event.target.value})
    }


    const onSubmitResults = (event: any) =>{
        event.preventDefault();
        //Check if submitted is false before submitting
        setSubmitted(true);
    }


    const buttonDisabledState = (): boolean => {

        return !(inputState.typeOfGoods && inputState.shipmentValue >0 && inputState.email);
    }


    return(<div className={"app-container"}>
        <div className={"header"}>
        {/*<h1 className={"heading"}>Risk Insurance Cost Calculator</h1>*/}
        {/*<h2 className={"sub-heading"}>How Exposed Are Your Shipments?</h2>*/}
        {/*    <div className={'disclaimer'}>*/}
        {/*<p >This calculator is designed to provide an Risk Insurance estimate. These rates do not constitute an offer of insurance, a contract, nor imply coverage.*/}
        {/*    To obtain a final insurance rate and secure coverage for your shipment please contact us at the “About” page. </p>*/}
        {/*    </div>*/}
            <div >
                <div className={"timeline-container"}>
                <div className={"protection-banner"}>
                    Where You're Protected

                </div>
                <div className={'indicator-border'}></div>
                <div className={'dotted-line'}></div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div className = {'timeline-indicator'} style={{width: `calc(55vw * 0.1428*${outputs.timelineIndicator.width})`, marginLeft:`calc(55vw * 0.1428*${outputs.timelineIndicator.startingPosition})`, backgroundColor: outputs.timelineIndicator.color, borderColor:outputs.timelineIndicator.borderColor}}></div>
           <div className={'timeline'}>

               {outputs.timeline.timelineElements.map((element, index)=>{
                   return (
                       <div className={"timeline-element-section"}>
                           <div>
                           <TimelineIcon selected={element.selected}  color={outputs.timeline.timelineColor} icon={element.icon} totalSelected={outputs.calcs.elementsCoveredNumber} title={element.title} index={index}/>

                           </div>
                       </div>

                   )
               })}
           </div>
           </div>
                </div>
            </div>
            <div className={"text-banner"}>Fill out the information below to calculate your estimated shipment risk</div>
            <div className={"calculator"}>

                <div className={"calc-column"}>
                <form>

                    <p>Average Shipment Value (USD)</p>
                    <input className={"calc-input"} type={"number"}  onChange={handleOnShipmentValueChange}/>


                    <h4>Select Cover Type</h4>
                    <div className={"checkboxes"}>
                        {inputState.checkboxes.map((checkbox, index) => {
                            return (<div className={"checkbox-container"}>
                                <div style={{color : checkbox.isChecked?"black":"gray"}}>
                                    <input className={"checkbox-round"} type="checkbox" checked={checkbox.isChecked}
                                           onChange={() => handleOnCheckboxChange(index)}/>
                                    {checkbox.label}
                                </div>
                            </div>)
                        })}
                    </div>

                </form>




                </div>

                <div className={"outputs-column calc-column"}>

                   <div>
                    <h4 className={"outputs-heading"}>Risk Exposure</h4>
                    <div className={'outputs'}>
                        {submitted?(<div>
                                <ExposureIcon currentExposure={outputs.calcs.currentExposure} ></ExposureIcon>


                        <div className={"output-container"}>
                            <p>Value at Risk</p>
                            <div className={"output"}>
                                {/*${outputs.valueAtRisk}*/}
                                ${outputs.calcs.valueAtRisk}
                            </div>
                        </div>
                        <div className={"output-container"}>
                            <p>*Timeline Covered</p>
                            <div className={"output timeline-covered"}>
                                {/*{outputs.timelineCovered}%*/}
                                {outputs.calcs.timelineCovered}%
                            </div>
                        </div>
                    </div>
                        ):(
                            <div className={"output-placeholder"}>
                                <p>  Please submit to see results </p>
                            </div>
                        )}
                    </div>

                </div>
            </div>

        </div>
        </div>


    </div>)
}
export default App;
