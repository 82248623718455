import React from 'react'
import {borderColors, exposureValues} from "../utils/constants";


const ExposureIcon = (props: {currentExposure: number}) =>{
let blockColor:  string;
let blockText: string;
    if(props.currentExposure === 0){
    blockColor = borderColors.GREEN;
    blockText = exposureValues.LOW;
} else if(props.currentExposure <= 50){
        blockColor = borderColors.ORANGE;
        blockText = exposureValues.MEDIUM;
    } else{
        blockColor = borderColors.RED;
        blockText = exposureValues.HIGH;
    }


  return (
      <div className={"output-container"} style={{borderColor: `${blockColor}`}}>
          <p>Current Exposure</p>
      <div className={"output"} style = {{color: `${blockColor}`, borderColor: `${blockColor}`}}>
          {props.currentExposure}%
      <div className={'exposure-icon'} style={{backgroundColor : `${blockColor}`, marginLeft: "auto"}}>
          {blockText}
      </div>
      </div>
          </div>
  )
};

export default ExposureIcon;