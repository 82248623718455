

export interface IComponentState {
    outputs: IOutputs,
    inputs: IInputs,
    timeline: ITimeLine,
    timelineIndicator: ITimelineIndicator,
    submitted: boolean
}


export interface IInputs {
    email : string,
    shipmentValue: number,
    typeOfGoods: string,
    checkboxes: ICheckbox[]
}

export interface IOutputs {
    calcs: ICalcs,
    timeline: ITimeLine,
    timelineIndicator: ITimelineIndicator
}






export interface ICheckbox  {isChecked: boolean, label: string, weight: number[]};

export interface ICalcs {currentExposure: number, valueAtRisk: number, timelineCovered: number, elementsCoveredNumber: number}
//export interface IInputs   { shipmentValue: number, email: string, typeOfGoods: string}

export enum typeOfGoodsValues{
    TECHNOLOGY= "Technology",
    CLINICAL_TRAILS= "Clinical Trial Materials & Equipment",
    MEDICAL_EQUIPMENT = "Medical Equipment",
    E_COMMERCE = "E-Commerce",
    SHOPFITTING = "Shopfitting",
    AEROSPACE = "Aerospace Components",
    RETAIL = "Retail",
    PROJECT_CARGO ="Project Cargo",
    INDUSTRIAL_MACHINERY = "Industrial Machinery",
    MARKETING_MATERIAL = "Marketing Material"
}



export interface ITimelineIcon {selected: boolean, color: timelineColors, icon?: string, totalSelected: number, title: string, index: number}

export interface ITimelineIndicator{ startingPosition: number, width: number, color: string, borderColor: string}


export enum timelineColors {GREEN = "mediumseagreen", ORANGE = "orange", RED = "#f77260", INACTIVE = 'lightgray'};
export interface ITimelineElement {icon: string, selected: boolean, title: string}
export interface ITimeLine {timelineColor: timelineColors, timelineElements: ITimelineElement[]}

